
.class-manage-level {
    padding: 10px 20px 20px;
}
::v-deep .resources-content {
    z-index: 1;
    .tab-txt {
        .iconfont {
            color: #909399;
            font-size: 18px;
            margin-right: 5px;
            position: relative;
            //top: -2px;
        }
    }
    .el-tabs__item.is-active {
        .iconfont {
            color: #409EFF;
        }
    }
    .el-tabs__item:hover {
        .iconfont {
            color: #409EFF;
        }
    }
}
.add-class-btn {
    position: absolute;
    top: 0;
    right: 0;
}
