
.upload-btn {
    position: relative;
    width: 122px;
    height: 90px;
    background: #D3E8FF;
    border-color: transparent;
    span {
        display: block;
        width: 100%;
        height: 100%;
        line-height: 70px;
        position: relative;
    }
    input {
        width: 122px;
        height: 90px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
    }
}
.up-img-show {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 122px;
    height: 90px;
    background-color: #fff;
    border: 1px solid #bfbfbf6b;
    img {
        max-width: 100%;
        max-height: 100%;
    }
    .cover-bg {
        position: absolute;
        bottom: 0;
        width: 122px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: #fff;
        background: #000000;
        opacity: 0.5;
        input {
            width: 122px;
            height: 30px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }
    }
}
.cover-text {
    position: absolute;
    bottom: -48px;
    left: 0;
    font-size: 12px;
    color: #999;
}
